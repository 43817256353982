import React, { FC, useMemo } from "react";
import styled, { css } from "styled-components";
import { Image } from "../../types";
import SVG from "react-inlinesvg";
import ReactPlayer from "react-player";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

import { CustomAccordion } from "../../components/CustomAccordion";
import { Script } from "../../components/Script";
import Link from "../shared/Link";
import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwDesktop,
  vwMobile,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";

import FbIcon from "../../assets/images/facebookIcon.svg";
import LinkedinIcon from "../../assets/images/linkedinIcon.svg";
import TwitterIcon from "../../assets/images/twitterIcon.svg";
import EmailIcon from "../../assets/images/emailIcon.svg";
import DownloadIcon from "../../assets/images/downloadIcon.svg";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { BackButton } from "../shared/BackButton";
import useRightToLeft from "../../hooks/useRightToLeft";

export type ArticleDetailMainProps = {
  image: Image;
  back: { label: string; link: string };
  type: string;
  tags: string[];
  title: string;
  date: string;
  content: ContentProps[];
  faqTitle: string;
  faq: FaqProps[];
  file?: string;
};

type ContentProps = {
  name: string;
  detail: string;
  video_url: string;
  script_url: string;
};

type FaqProps = {
  question: string;
  answer: string;
};

const ArticleDetailMain: FC<ArticleDetailMainProps> = ({
  image,
  back,
  type,
  tags,
  title,
  date,
  content = [],
  faqTitle,
  faq = [],
  file,
}: ArticleDetailMainProps) => {
  const labels = useStaticLabels();
  const isRTL = useRightToLeft();

  const url = useMemo(() => {
    if (typeof window !== "undefined") {
      return window?.location?.href;
    }
  }, []);

  const getTags = () => {
    return tags.join(", ");
  };

  const getDescription = () => {
    return `${title}\n${type}\n${getTags()}\n${date}`;
  };

  const hasContent = useMemo(() => {
    return faq?.length > 0 || content?.length > 0;
  }, [content, faq]);

  return (
    <>
      <Header>
        <BackContainer>
          <BackButton label={back?.label} link={back?.link} />
        </BackContainer>
        <MainWrapper>
          {image?.src && (
            <MainImageWrapper>
              <MainImage src={image?.src} alt={image?.alt} />
            </MainImageWrapper>
          )}
          <TitleWrapper isRTL={isRTL} fullWidth={!image?.src}>
            <TagsWrapper>
              <Type>{type}</Type>
              <Tags>{getTags()}</Tags>
            </TagsWrapper>
            {title && <Title>{title}</Title>}
            <Date>{date}</Date>
            <SocialIconsWrapper>
              <FacebookShareButton
                quote={getDescription()}
                hashtag={`#BeyondMeat`}
                url={url}
              >
                <SmallBtn src={FbIcon} />
              </FacebookShareButton>
              <TwitterShareButton
                title={getDescription()}
                hashtags={["BeyondMeat"]}
                related={["@beyondmeat"]}
                url={url}
              >
                <SmallBtn src={TwitterIcon} />
              </TwitterShareButton>
              <LinkedinShareButton
                title={title}
                summary={getDescription()}
                url={url}
              >
                <SmallBtn src={LinkedinIcon} />
              </LinkedinShareButton>
              <EmailShareButton
                subject={title}
                body={getDescription()}
                url={url}
              >
                <SmallBtn src={EmailIcon} />
              </EmailShareButton>
              {file && (
                <Link to={file} aria-label={labels?.download}>
                  <SmallBtn src={DownloadIcon} />
                </Link>
              )}
            </SocialIconsWrapper>
          </TitleWrapper>
        </MainWrapper>
      </Header>
      {hasContent && (
        <SectionsWrapper>
          {content?.map((element) => (
            <>
              <Section
                key={element?.name}
                dangerouslySetInnerHTML={{
                  __html: element?.detail,
                }}
              />
              {element?.video_url && (
                <ReactPlayerContainer>
                  <StyledReactPlayer url={element?.video_url} />
                </ReactPlayerContainer>
              )}
              {element?.script_url && (
                <Script scriptUrl={element?.script_url} />
              )}
            </>
          ))}
          <FAQ>
            {faqTitle && <FAQTitle>{faqTitle}</FAQTitle>}
            {faq?.map((faqElement, index) => (
              <CustomAccordion
                key={index}
                title={faqElement?.question}
                text={faqElement?.answer}
              />
            ))}
          </FAQ>
        </SectionsWrapper>
      )}
    </>
  );
};

const Header = styled.section`
  background-color: #033305;
`;

const BackContainer = styled.div`
  padding: ${toVWMobile(40)}vw ${toVWMobile(20)}vw;

  a {
    margin-bottom: 0;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(42)}vw ${toVWDesktop(102)}vw;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Arrow = styled(SVG)`
  width: ${toVWMobile(20)}vw;
  height: ${toVWMobile(11)}vw;
  fill: #75c154;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(20)}vw;
    height: ${toVWDesktop(11)}vw;
  }
`;

const BackText = styled.span`
  color: #75c154;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: ${toREM(0.16)}rem;
  line-height: normal;
  margin-left: ${toVWMobile(8)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: ${toVWDesktop(8)}vw;
  }
`;

const MainWrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const MainImageWrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(710)}vw;
    height: ${toVWDesktop(467)}vw;
    margin-bottom: ${toVWDesktop(80)}vw;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const TitleWrapper = styled.div<{ fullWidth: boolean; isRTL?: boolean }>`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: normal;
  color: #fff;
  padding: ${toVWMobile(40)}vw ${toVWMobile(19)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0;
    width: ${(props) => (props?.fullWidth ? "100%" : toVWDesktop(400))}vw;
    margin-left: ${(props) => (props?.fullWidth ? toVWDesktop(205) : 0)}vw;
    margin-right: ${(props) =>
      props?.fullWidth ? toVWDesktop(205) : toVWDesktop(125)}vw;
    margin-bottom: ${toVWDesktop(80)}vw;

    ${({ isRTL, fullWidth }) => {
      return (
        isRTL &&
        css`
          margin-left: ${fullWidth ? toVWDesktop(205) : toVWDesktop(125)}vw;
        `
      );
    }}
  }
`;

const TagsWrapper = styled.div`
  margin-bottom: ${vwMobile(4)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(32)}vw;
    margin-bottom: ${toVWDesktop(15)}vw;
    display: flex;
    gap: ${vwDesktop(15)};
  }
`;

const Type = styled.div`
  color: #ccb983;
  text-transform: uppercase;
`;

const Tags = styled.div`
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex: 1;
    margin-bottom: 0;
  }
`;

const Title = styled.h1`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(44)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${toVWMobile(52)}vw;
  text-transform: uppercase;
  margin-bottom: ${toVWMobile(25)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    line-height: ${toVWDesktop(64)}vw;
    margin-bottom: ${toVWDesktop(30)}vw;
  }
`;

const Date = styled.p`
  margin-bottom: ${toVWMobile(41)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(41)}vw;
  }
`;

const SocialIconsWrapper = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: ${toVWMobile(12)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-right: ${toVWDesktop(12)}vw;
    }
  }
`;

const SmallBtn = styled(SVG)`
  width: ${toVWMobile(28)}vw;
  height: ${toVWMobile(28)}vw;

  path {
    fill: #75c154;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(28)}vw;
    height: ${toVWDesktop(28)}vw;
  }
`;

const SectionsWrapper = styled.div`
  background-color: #fefbf4;
  padding: ${toVWMobile(44)}vw ${toVWMobile(20)}vw ${toVWMobile(80)}vw
    ${toVWMobile(18)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(85)}vw ${toVWDesktop(415)}vw ${toVWDesktop(80)}vw
      ${toVWDesktop(310)}vw;
  }
`;

const Section = styled.div`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  color: #033305;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toREM(28)}rem;

  h2,
  h3,
  h4 {
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: ${toVWMobile(30)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(31)}vw;
    }
  }

  h2 {
    font-size: ${toREM(44)}rem;
    letter-spacing: ${toREM(1.32)}rem;
    line-height: ${toVWMobile(56)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      font-size: ${toREM(48)}rem;
      letter-spacing: ${toREM(1.44)}rem;
      line-height: ${toVWDesktop(56)}vw;
    }
  }

  h2 a,
  h2 a:link {
    font-size: ${toREM(44)}rem;
    letter-spacing: 0.03em;
    line-height: ${toREM(56)}rem;
    font-weight: 800;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      font-size: ${toREM(48)}rem;
    }
  }

  h3 {
    font-size: ${toREM(36)}rem;
    letter-spacing: ${toREM(1.08)}rem;
    line-height: ${toVWMobile(40)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      line-height: ${toVWDesktop(40)}vw;
    }
  }

  h3 a,
  h3 a:link {
    font-size: ${toREM(36)}rem;
    letter-spacing: ${toREM(1.08)}rem;
    line-height: ${toVWMobile(40)}vw;
    font-weight: 800;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      line-height: ${toVWDesktop(40)}vw;
    }
  }

  h4 {
    font-size: ${toREM(24)}rem;
    letter-spacing: ${toREM(1.44)}rem;
    line-height: normal;
  }

  h4 a,
  h4 a:link {
    font-size: ${toREM(24)}rem;
    letter-spacing: ${toREM(1.44)}rem;
    line-height: normal;
    font-weight: 800;
  }

  a {
    color: #457f2e;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-decoration: none;
  }

  p {
    margin-bottom: ${toVWMobile(46)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(47)}vw;
    }
  }

  ul,
  ol {
    padding-left: ${toVWMobile(18)}vw;
    margin-bottom: ${toVWMobile(40)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding-left: ${toVWDesktop(20)}vw;
      margin-bottom: ${toVWDesktop(40)}vw;
    }
  }

  li {
    margin-bottom: ${toVWMobile(28)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(20)}vw;
    }

    ul,
    ol {
      padding-left: ${toVWMobile(50)}vw;
      margin-top: ${toVWMobile(34)}vw;
      margin-bottom: 0;

      @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
        padding-left: ${toVWDesktop(40)}vw;
        margin-top: ${toVWDesktop(35)}vw;
      }
    }
  }

  img {
    height: auto;
    object-fit: contain;
    max-width: 100%;
  }

  .row {
    display: flex;
    margin-bottom: ${toVWMobile(46)}vw;
    gap: ${toVWMobile(5)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(47)}vw;
      gap: ${toVWDesktop(19)}vw;
    }
  }

  .column {
    flex: 1;
  }
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
`;

const ReactPlayerContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: ${toVWMobile(77)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(70)}vw;
  }
`;
const FAQ = styled.div`
  color: #012102;
`;

const FAQTitle = styled(Title)`
  margin-bottom: 0;
`;

export default ArticleDetailMain;
