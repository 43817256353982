import React, { useCallback } from "react";
import { useIntl } from "../hooks/useIntl";
import { graphql } from "gatsby";
import moment from "moment";
import { getAssetURL } from "../helpers/assets";
import { getTranslations } from "../helpers/translations";
import ArticleDetailMain, {
  ArticleDetailMainProps,
} from "../components/ArticleDetailMain";

import Page from "../components/Page";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { ROUTES } from "../helpers/routes";
import { OG_TYPES } from "../components/SEO/SEO";
import { useStaticLabels } from "../hooks/useStaticLabels";

type tagType = {
  article_tag_id: articleTagType[];
};

type articleTagType = {
  translations: translationsTagType[];
};

type translationsTagType = {
  tag_name: string;
};

const Article = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    const article = data?.directus?.article_by_id;
    const translatedArticle =
      getTranslations(article, "translations", intl.locale) ?? {};
    return {
      ...translatedArticle,
      article_slug: article?.article_slug,
      article_type: article?.article_type,
    };
  }, [data]);

  const getArticleDetailMainData = useCallback((): ArticleDetailMainProps => {
    const translatedData = getRootTranslatedData();

    const translatedTypeData = getTranslations(
      translatedData,
      "article_type.translations",
      intl.locale
    );

    const articleType = data?.directus?.article_by_id?.article_type?.type_value;

    const tagsArray: string[] = [];

    translatedData?.tags?.forEach((tag: tagType) => {
      const translatedData = getTranslations(
        tag,
        "article_tag_id.translations",
        intl.locale
      );
      tagsArray.push(translatedData?.tag_name);
    });

    const fromBlogs = articleType === "blog";

    let backInfo = {
      label: labels?.backToAllBlogsLabel,
      link: `${ROUTES.NEWSROOM}#${articleType}`,
    };
    if (!fromBlogs) {
      backInfo = {
        label: labels?.backToAllPressReleasesLabel,
        link: `${ROUTES.NEWSROOM}#${articleType}`,
      };
    }

    return {
      image: {
        src: getAssetURL(translatedData?.article_image),
        alt: translatedData?.article_image_alt,
      },
      thumbnail: {
        src: getAssetURL(translatedData?.article_thumbnail),
        alt: translatedData?.article_thumbnail_alt,
      },
      back: backInfo,
      type: translatedTypeData?.article_type_label,
      tags: tagsArray,
      title: translatedData?.article_title,
      date: moment(translatedData?.article_date).format("MMMM D, YYYY"),
      content: translatedData?.article_content,
      faqTitle: translatedData?.article_questions_title,
      faq: translatedData?.article_questions_accordion,
      file: translatedData?.article_file
        ? getAssetURL(translatedData?.article_file)
        : "",
    };
  }, [getRootTranslatedData]);

  const articleData = getArticleDetailMainData();

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={
        <SEO
          {...parseSEO(getRootTranslatedData(), intl?.locale, {
            og_image: articleData?.image?.src,
            og_type: OG_TYPES.article,
          })}
        />
      }
    >
      <ArticleDetailMain {...articleData} />
    </Page>
  );
};

export default Article;

export const query = graphql`
  query ($id: ID!, $locale: String) {
    directus {
      article_by_id(id: $id) {
        article_slug
        article_type {
          type_value
          translations(filter: { languages_code: { code: { _eq: $locale } } }) {
            languages_code {
              name
              code
            }
            article_type_label
          }
        }
        id
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            name
            code
          }
          status
          tags {
            article_tag_id {
              tag_type {
                type_value
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    name
                    code
                  }
                  article_type_label
                }
              }
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  name
                  code
                }
                tag_name
              }
            }
          }
          info_status
          article_title
          article_description
          article_date
          article_image_alt
          article_image {
            id
            filename_disk
          }
          article_thumbnail_alt
          article_thumbnail {
            id
            filename_disk
          }
          article_file {
            id
            filename_disk
          }
          article_content
          article_questions_title
          article_questions_accordion
        }
      }
      article_tag {
        tag_type {
          type_value
          status
          id
          translations(filter: { languages_code: { code: { _eq: $locale } } }) {
            id
            languages_code {
              code
              name
            }
            article_type_label
          }
        }
        id
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          id
          languages_code {
            code
            name
          }
          tag_name
        }
      }
    }
  }
`;
