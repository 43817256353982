import { FC } from "react";
import useScript from "../../hooks/useScript";

export type ScriptProps = {
  scriptUrl: string;
};

const Script: FC<ScriptProps> = ({
  scriptUrl,
}: ScriptProps) => {
    useScript(scriptUrl);
    return null;
};

export default Script;
